import { createContext, ReactNode, useContext, useState } from 'react';

type DataSearchContextProps = {
  highlightedWords: string[];
  setHighlightedWords: (words: string[]) => void;
};

export const HighlightContext = createContext<DataSearchContextProps>({
  highlightedWords: [],
  setHighlightedWords: () => {},
});

type HighlightContextProviderProps = {
  children: ReactNode;
};

export const HighlightContextProvider = ({
  children,
}: HighlightContextProviderProps) => {
  const [highlightedWords, setHighlightedWords] = useState<string[]>([]);

  const props: DataSearchContextProps = {
    highlightedWords,
    setHighlightedWords,
  };

  return (
    <HighlightContext.Provider value={props}>
      {children}
    </HighlightContext.Provider>
  );
};

export const useHighlight = () => useContext(HighlightContext);
