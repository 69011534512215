import {
  Industry,
  PublicPages_CaseStudyCompanyInformationFragment,
} from '@collective/data-type';
import { TextTooltip } from '@collective/ui';
import { Box, Divider, Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography/text';
import { useIsomorphicIsMobile } from '@collective/utils/frontend';
import { useMemo } from 'react';

import { HighlightText } from '../highlighting/highlight-text';
import { ImageWrapped } from '../image-wrapped/image-wrapped';
import { useIndustriesTranslationMapping } from '../utils/industries-translation';
import { useSegmentsTranslationMapping } from '../utils/segments-translation';

type PublicCaseStudyInfoHeaderType = {
  companyInformation?: PublicPages_CaseStudyCompanyInformationFragment | null;
};

export const PublicCaseStudyInfoHeader = ({
  companyInformation,
}: PublicCaseStudyInfoHeaderType) => {
  if (!companyInformation) {
    return null;
  }

  const { segment, industries: objectIndustries } = companyInformation;
  const industries = (objectIndustries || []).map(({ value }) => value);

  return (
    <HighlightText>
      <Flex
        align="center"
        whiteSpace="nowrap"
        wrap="wrap"
        rowGap={2}
        overflow="hidden"
      >
        {companyInformation?.picture && (
          <ImageWrapped
            w="24px"
            h="24px"
            borderRadius="8px"
            src={companyInformation.picture}
            srcSetSizes={['24px']}
            scale={4}
            alt={`${companyInformation.name} logo`}
            mr="8px"
          />
        )}
        {companyInformation?.name && (
          <Text variant="desktop-m-bold" mr="20px">
            {companyInformation.name}
          </Text>
        )}
        <BulletItems industries={industries} segment={segment} />
      </Flex>

      <Divider mt={4} mb={6} />
    </HighlightText>
  );
};

type BulletItemsProps = {
  industries: Industry[];
  segment?: PublicPages_CaseStudyCompanyInformationFragment['segment'];
};

const BulletItems = ({ industries, segment }: BulletItemsProps) => {
  const isMobile = useIsomorphicIsMobile();
  const industriesTranslationMapping = useIndustriesTranslationMapping();
  const segmentsTranslationMapping = useSegmentsTranslationMapping('short');

  const overflowTooltipLabel = useMemo(() => {
    const lines = industries.map((industry) => {
      return `• ${industriesTranslationMapping[industry]}`;
    });
    if (segment) {
      lines.push(`• ${segmentsTranslationMapping[segment]}`);
    }
    return lines.join('\n');
  }, [
    industries,
    industriesTranslationMapping,
    segment,
    segmentsTranslationMapping,
  ]);

  const bulletItems = industries
    .map((industry) => industriesTranslationMapping[industry])
    .concat(segment ? [segmentsTranslationMapping[segment]] : []);

  if (bulletItems.length === 0) {
    return null;
  }

  if (isMobile) {
    return (
      <Flex wrap="wrap" width="100%">
        {bulletItems.map((bulletItem) => (
          <Text key={bulletItem} overflow="hidden" textOverflow="ellipsis">
            <BulletItem label={bulletItem} />
          </Text>
        ))}
      </Flex>
    );
  }

  return (
    <TextTooltip
      // Negative margin is a trick to hide the first dot when it wraps on the 2nd line
      ml="-20px"
      color="rythm.700"
      tooltipProps={{
        placement: 'top-end',
        openDelay: 0,
        label: <Box whiteSpace="pre">{overflowTooltipLabel}</Box>,
      }}
    >
      {bulletItems.map((bulletItem) => (
        <BulletItem key={bulletItem} label={bulletItem} />
      ))}
    </TextTooltip>
  );
};

const BulletItem = ({ label }: { label: string }) => {
  return (
    <>
      <Box as="span" display="inline-block" color="rythm.300" mx="6px" w="8px">
        •
      </Box>
      {label}
    </>
  );
};
