import utilsDate from '@collective/utils/date';
import cuid from 'cuid';
import { chain, isNil, minBy, partition, sortBy } from 'lodash';

import { UserProfileWorkExperience } from '../../types';

type WorkExperienceGroup = {
  id: string;
  company: string;
  items: UserProfileWorkExperience[];
};

export const groupWorkExperiences = (
  records: UserProfileWorkExperience[]
): WorkExperienceGroup[] => {
  const now = new Date();

  return (
    chain(records)
      // initial sort by ranking to have ranked items as first in the grouping
      .sortBy('ranking')
      // group by company name
      .groupBy('company')
      // We put the active experience first, then the old ones, sorted on endDate then startDate
      // It's important to have this initial sort to calculate easily the date overlaps
      .map((group) => {
        const [currentXP, pastXP] = partition(group, ({ endDate }) => !endDate);

        return [
          ...sortBy(currentXP, 'startDate')
            .reverse()
            .sort((a, b) => {
              if (isNil(a.ranking) && !isNil(b.ranking)) {
                return 1;
              } else if (!isNil(a.ranking) && isNil(b.ranking)) {
                return -1;
              } else {
                return (a.ranking || 0) - (b.ranking || 0);
              }
            }),
          ...chain(pastXP).sortBy(['endDate', 'startDate']).reverse().value(),
        ];
      })
      // We check if experiences overlap with each others. If not, we create new groups
      // This way, if for a same company you have a gap of some months/years, you'll have 2 (or more) groups
      .reduce<UserProfileWorkExperience[][]>((groups, group) => {
        const newSubgroups: UserProfileWorkExperience[][] = [];

        for (const experience of group) {
          const overlapedInGroup = newSubgroups.find((subgroup) =>
            subgroup.find(
              (xp) =>
                xp.id !== experience.id &&
                utilsDate.isOverlaping(
                  [xp.startDate || now, xp.endDate || now],
                  [experience.startDate || now, experience.endDate || now]
                )
            )
          );
          if (overlapedInGroup) {
            overlapedInGroup.push(experience);
          } else {
            newSubgroups.push([experience]);
          }
        }

        // We don't have to re-order the content of each groups as they were in the right order
        // And we push back values to the new subgroups should keep the right order

        return [...groups, ...newSubgroups];
      }, [])
      // We format the data of the groups
      .map((group) => {
        return {
          id: cuid(),
          company: group[0].company || cuid(),
          items: group,
        };
      })
      .value()
      // We re-sort as the new groups created are not sorted at the right place
      // The idea is to put the active groups first, the
      .sort((groupA, groupB) => {
        // Current work experience go first
        if (!groupA.items[0].endDate && groupB.items[0].endDate) {
          return -1;
        }
        if (groupA.items[0].endDate && !groupB.items[0].endDate) {
          return 1;
        }

        // If there is a ranking on current experience, we order them
        if (
          !groupA.items[0].endDate &&
          !groupB.items[0].endDate &&
          (!isNil(groupA.items[0].ranking) || !isNil(groupB.items[0].ranking))
        ) {
          return (
            (groupA.items[0].ranking || 0) - (groupB.items[0].ranking || 0)
          );
        }

        const groupAStartDate = getMinStartDateOfGroup(groupA.items);
        const groupBStartDate = getMinStartDateOfGroup(groupB.items);

        // Last sort condition is based on the start date, mainly for old experiences
        if (groupAStartDate && !groupBStartDate) {
          return -1;
        } else if (!groupAStartDate && groupBStartDate) {
          return 1;
        }

        return (
          new Date(groupBStartDate || now).getTime() -
          new Date(groupAStartDate || now).getTime()
        );
      })
  );
};

export const getMinStartDateOfGroup = (
  groupItems: UserProfileWorkExperience[] | undefined
) => {
  return minBy(groupItems, (item) => item.startDate)?.startDate;
};
