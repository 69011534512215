export const MOCKED_LINKEDIN_USER_DATA = {
  city: 'Seattle',
  country: 'US',
  country_full_name: 'United States',
  education: [
    {
      grade: 'Graduate with distinction',
      school: 'Oxford',
      ends_at: {
        day: 31,
        year: 2018,
        month: 1,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/cloelscwe04g893c24wnyhzft-logo.jpg',
      starts_at: {
        day: 1,
        year: 2014,
        month: 10,
      },
      degree_name: 'Bachelor',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer quis ultrices diam. Nam volutpat lorem tellus, a venenatis leo finibus non. Sed ut bibendum magna. Fusce iaculis, mi eget egestas euismod, diam tellus consequat tellus, ac tincidunt nibh nisl vel massa. Etiam ac justo ex. Aenean tempus leo nec leo imperdiet pellentesque.',
      field_of_study: 'Computer Science',
      activities_and_societies:
        'Sed nulla ante, egestas et elementum quis, pellentesque sed leo. Mauris vel tempus urna. Morbi eu dignissim turpis, sed consectetur lorem. Phasellus sed pretium ex. Donec risus tortor, ullamcorper non placerat et, faucibus ut enim. Nam commodo lorem eu imperdiet consectetur.',
      school_linkedin_profile_url: null,
    },
    {
      grade: 'Graduate',
      school: 'Oxford',
      ends_at: {
        day: 1,
        year: 2019,
        month: 10,
      },
      logo_url:
        'https://cdn.collective.work/profiles-assets/cloelscwe04g893c24wnyhzft-logo.jpg',
      starts_at: {
        day: 31,
        year: 2018,
        month: 1,
      },
      degree_name: 'Master of Science',
      description:
        'Suspendisse pellentesque dolor vitae enim porttitor venenatis. Praesent dignissim lorem quis elit consectetur, nec egestas justo dignissim. Sed a euismod orci, sit amet interdum nisl. Cras in laoreet quam. Etiam orci massa, finibus convallis dapibus et, elementum non nisl. Fusce bibendum molestie elit, ac placerat mauris aliquet a. Donec et odio mi. Vivamus tempor, tellus a fermentum blandit, nisi elit viverra ligula, non elementum augue dolor sit amet ipsum.',
      field_of_study: 'Computer Science',
      activities_and_societies: null,
      school_linkedin_profile_url: null,
    },
  ],
  experiences: [
    {
      company: 'YouTube',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/youtube',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse posuere vulputate dolor, sit amet rhoncus tortor varius eget. Duis ut magna nisl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      ends_at: null,
      location: 'San Bruno, California',
      logo_url: 'https://cdn.collective.work/clo6sua1c000108mg5lp23as8.jpeg',
      starts_at: {
        day: 1,
        month: 1,
        year: 2023,
      },
      title: 'CTO',
    },
    {
      company: 'YouTube',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/youtube',
      description:
        'Aenean maximus ut ipsum vitae interdum. Fusce ullamcorper purus eget urna iaculis, vel hendrerit sapien tempus. Aliquam efficitur sit amet leo sit amet venenatis. Pellentesque turpis sapien, mattis eleifend massa ut, posuere consequat orci.',
      ends_at: {
        day: 1,
        month: 1,
        year: 2022,
      },
      location: 'San Bruno, California',
      logo_url: 'https://cdn.collective.work/clo6sua1c000108mg5lp23as8.jpeg',
      starts_at: {
        day: 1,
        month: 1,
        year: 2019,
      },
      title: 'Senior Full-Stack Developer',
    },
    {
      company: 'YouTube',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/youtube',
      description:
        'Nulla in aliquet lectus, eu semper diam. Phasellus rutrum placerat dolor eget suscipit. Nam porttitor tincidunt dapibus. Vestibulum volutpat nunc magna, id semper neque malesuada ut.',
      ends_at: {
        day: 1,
        month: 1,
        year: 2019,
      },
      location: 'San Bruno, California',
      logo_url: 'https://cdn.collective.work/clo6sua1c000108mg5lp23as8.jpeg',
      starts_at: {
        day: 15,
        month: 1,
        year: 2017,
      },
      title: 'Full-Stack Developer',
    },
    {
      company: 'Amazon',
      company_linkedin_profile_url: 'https://www.linkedin.com/company/amazon',
      description:
        'Nunc sed elit urna. Pellentesque ac semper orci. Nam elementum vitae odio at feugiat. Nulla quis viverra massa. Pellentesque ac euismod nisl. Vestibulum lobortis, est eget accumsan pretium, ligula neque rutrum quam, et accumsan sapien sapien et magna. Praesent tincidunt eros efficitur, porta leo id, condimentum quam. Integer eu libero ex.',
      ends_at: null,
      location: 'Seattle, Washington',
      logo_url: 'https://cdn.collective.work/clo6su3cu000008mgevgugwy9.jpeg',
      starts_at: null,
      title: 'Senior Product Manager',
    },
  ],
  first_name: 'John',
  full_name: 'John Doe',
  headline: 'Financial Freedom through Real Estate - LinkedIn Top Voice',
  languages: ['English', 'Spanish', 'Japanese'],
  last_name: 'Doe',
  profile_pic_url: 'https://new-avatar-url',
  occupation: 'CEO',
};
