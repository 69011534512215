import { Flex, FlexProps } from '@collective/ui';
import { forwardRef } from 'react';

export type WrapperViewProps = FlexProps;

export const WrapperView = forwardRef(function WrapperView(
  { children, ...rest }: WrapperViewProps,
  ref
) {
  return (
    <Flex ref={ref} px={10} gap={{ base: 6, '3xl': 10, '4xl': 14 }} {...rest}>
      {children}
    </Flex>
  );
});
