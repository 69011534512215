import { Box, Flex, IconSmallStar, Tag, Text } from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../../highlighting/highlight-text';
import { useSkillSort } from './use-skill-sort';

type SkillType = {
  code: string;
  isFeatured?: boolean | null;
};

type UserSkillsProps = {
  skills: SkillType[];
  showAllSkills?: boolean;
  filters?: string[];
};

export const UserSkills = ({
  skills,
  showAllSkills,
  filters = [],
}: UserSkillsProps) => {
  const { t } = useTranslation('common');

  const reorderedSkills = useSkillSort(skills, filters);

  if (skills.length === 0) {
    return null;
  }

  const showAllSkillsProps = showAllSkills
    ? {
        maxH: { base: '132px' },
        overflow: 'hidden',
      }
    : {
        maxH: { base: '132px', md: '26px' },
        overflow: 'hidden',
      };

  return (
    <Box mt={5}>
      <Text variant="desktop-s-semibold" color="rythm.700" mb={1}>
        {t('profile.tags.skills')}
      </Text>

      <Flex
        gap={1}
        flexWrap="wrap"
        maxH={showAllSkillsProps.maxH}
        overflow={showAllSkillsProps.overflow}
      >
        {reorderedSkills.map(({ value, isFeatured }) => (
          <Tag
            key={value}
            label={<HighlightText>{value}</HighlightText>}
            color="rythm.700"
            bgColor="rythm.100"
            size="sm"
            leftIcon={isFeatured ? IconSmallStar : undefined}
          />
        ))}
      </Flex>
    </Box>
  );
};
