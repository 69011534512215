import React, { cloneElement, isValidElement, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { useHighlight } from './highlight-context';

interface HighlightTextProps {
  children: ReactNode;
}

export const HighlightText: React.FC<HighlightTextProps> = ({ children }) => {
  const { highlightedWords } = useHighlight();

  if (!highlightedWords.length) {
    return children;
  }

  const highlightWord = (node: ReactNode, searchTerm: string): ReactNode => {
    if (typeof node === 'string') {
      const regex = new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi');
      const parts = node.split(regex);
      return parts.map((part, index) =>
        regex.test(part) ? (
          <mark style={{ backgroundColor: '#FFE799' }} key={index}>
            {part}
          </mark>
        ) : (
          part
        )
      );
    } else if (isValidElement(node)) {
      if (node.type === Trans) {
        // Assume that Trans children are simple text or resolved to text
        return cloneElement(node, {
          ...node.props,
          parent: HighlightText,
          children: React.Children.map(node.props.children, (child) =>
            highlightWord(child, searchTerm)
          ),
        });
      } else {
        // General recursive handling for other types of components
        return cloneElement(node, {
          ...node.props,
          children: React.Children.map(node.props.children, (child) =>
            highlightWord(child, searchTerm)
          ),
        });
      }
    } else {
      return node;
    }
  };

  const highlightWords = (node: ReactNode): ReactNode => {
    let modifiedNode = node;

    for (const searchTerm of highlightedWords) {
      modifiedNode = highlightWord(modifiedNode, searchTerm);
    }

    return modifiedNode;
  };

  return <>{React.Children.map(children, highlightWords)}</>;
};

function escapeRegExp(str: string) {
  // \\$& Ensures the whole matched string (the special character) is included
  // in the replacement, preceded by a backslash.
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
