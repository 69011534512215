import { Tag } from '@collective/ui';
import { useTranslation } from 'react-i18next';

export const TagNew = () => {
  const { t } = useTranslation('common');
  return (
    <Tag
      label={t('ui.newTag')}
      size="sm"
      bg="rgba(255,255,255, 0.24)"
      color="white"
      p="2px 4px"
      borderRadius="4px"
    />
  );
};
