import {
  Collective_CaseStudyFragment,
  PublicPages_CaseStudyFragment,
} from '@collective/data-type';
import { AspectRatio, AvatarGroup } from '@collective/ui';
import { BorderBox, Box, Flex, Spacer } from '@collective/ui/lib/layout';
import { Heading, Text } from '@collective/ui/lib/typography';
import { addSearchParams } from '@collective/utils/helpers';
import { displayName, projectTypeUtils } from '@collective/utils/shared';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../highlighting/highlight-text';
import { ImageWrapped } from '../image-wrapped/image-wrapped';
import { publicPageAvatarImageParams } from '../utils/image-utils';

type CaseStudyCardProps = {
  caseStudy: PublicPages_CaseStudyFragment | Collective_CaseStudyFragment;
};

export const CaseStudyCard = ({ caseStudy }: CaseStudyCardProps) => {
  return (
    <BorderBox h="12.5rem" _focusWithin={{ borderColor: 'rythm.900' }}>
      <Flex h="100%">
        <Flex flex={1} p="20px 20px 16px" display="flex" flexDirection="column">
          <CardContent caseStudy={caseStudy} />
        </Flex>

        {caseStudy.coverImage && (
          <AspectRatio
            display={{ base: 'none', md: 'block' }}
            w="266px"
            borderLeft="1px solid"
            borderColor="rythm.200"
          >
            <ImageWrapped
              src={caseStudy.coverImage}
              srcSetSizes={['266px']}
              h="100%"
              withSkeleton
            />
          </AspectRatio>
        )}
      </Flex>
    </BorderBox>
  );
};

type CardContentProps = {
  caseStudy: PublicPages_CaseStudyFragment;
};

const CardContent = ({ caseStudy }: CardContentProps) => {
  const { t: commonT } = useTranslation('common');

  const getProjectTypeTranslation =
    projectTypeUtils.getTranslationMapping(commonT);

  const { companyInformation, projectOrganisation } = caseStudy;
  const hasMetadata = companyInformation && projectOrganisation;

  if (hasMetadata) {
    return (
      <HighlightText>
        <Heading variant="desktop-l-semibold" noOfLines={1} mb={2}>
          {companyInformation.name}
        </Heading>
        <Text color="rythm.700" noOfLines={1} mb={2}>
          {projectOrganisation.projectTypes.map(({ code }, index) => (
            <Fragment key={index}>
              {index > 0 && (
                <Box as="span" color="rythm.300" display="contents">
                  {' • '}
                </Box>
              )}
              {getProjectTypeTranslation(code)}
            </Fragment>
          ))}
        </Text>
        <Text noOfLines={3}>{caseStudy.title}</Text>

        <Spacer />
        <AvatarGroup
          avatars={projectOrganisation.users.map((user) => ({
            id: user.id,
            name: displayName(user),
            src: addSearchParams(user.pictureUrl, publicPageAvatarImageParams),
          }))}
          max={8}
          size="sm"
          borderColor="rythm.900"
          data-chromatic="ignore"
        />
      </HighlightText>
    );
  }

  // Simpler variant when there are no metadata
  return (
    <>
      <Heading variant="desktop-l-semibold" noOfLines={2} mb={2}>
        {caseStudy.title}
      </Heading>
      <Text noOfLines={5}>{caseStudy.description}</Text>
    </>
  );
};
