import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { cloneElement, ReactElement, useMemo } from 'react';

import { useAnimatedMultiStepLayer } from './animated-multi-step-layer-context';

const variants = {
  closed: {
    opacity: 0,
    zIndex: 0,
    position: 'absolute' as const,
    pointerEvents: 'none' as const,
  },
  open: {
    opacity: 1,
    zIndex: 1,
    // Workaround to fix a bug when the other step are way bigger than the first one
    // Should have been 'relative'
    position: 'sticky' as const,
    pointerEvents: 'auto' as const,
  },
};
const MotionStep = styled(motion.div)`
  top: 0;
  width: 100%;
`;

export type AnimatedStepProps<StepType = string> = {
  step: StepType;
  nextStep?: StepType;
  children: ReactElement;
};

export const AnimatedStep = ({
  children,
  step,
  nextStep,
}: AnimatedStepProps) => {
  const { currentStep, refs, transitionToStep, shouldUnmountStep } =
    useAnimatedMultiStepLayer();

  const child = useMemo(() => {
    return cloneElement(
      children,
      nextStep
        ? { ...children.props, goToNextStep: () => transitionToStep(nextStep) }
        : children.props
    );
  }, [children, nextStep, transitionToStep]);

  return (
    <MotionStep
      key={step}
      ref={refs[step]}
      animate={currentStep === step ? 'open' : 'closed'}
      variants={variants}
      aria-hidden={currentStep !== step}
      initial="closed"
    >
      {currentStep === step || !shouldUnmountStep ? child : null}
    </MotionStep>
  );
};
