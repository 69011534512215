import {
  Box,
  Flex,
  Heading,
  IconButton,
  IconLinkedIn,
  Link,
  Text,
  Tooltip,
} from '@collective/ui';
import { getLinkHref } from '@collective/utils/shared';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../../highlighting/highlight-text';
import { getLinkIcon } from '../../utils/links';
import { UserProfileLink } from '../types';
import { ProfileBox } from './profile-box';
import { OpportunityMember } from './types';

type SoloApplicationAboutSectionProps = {
  links: UserProfileLink[];
  member: OpportunityMember;
  onLinkClick: (link: string) => void;
};

export const SoloApplicationAboutSection = ({
  member,
  links,
  onLinkClick,
}: SoloApplicationAboutSectionProps) => {
  const { t } = useTranslation('common');

  const { intro, linkedInUrl, resumeUrl } = member;

  if (!intro && !linkedInUrl && !resumeUrl && !links.length) {
    return null;
  }

  return (
    <ProfileBox>
      <Box>
        <Heading variant="desktop-m-bold" mb={5}>
          {t('profile.application.about.header')}
        </Heading>

        <Flex direction="column" gap="24px">
          <IntroSection intro={intro} />
          <LinksSection
            member={member}
            links={links}
            onLinkClick={onLinkClick}
          />
        </Flex>
      </Box>
    </ProfileBox>
  );
};

type IntroSectionProps = {
  intro: string | null | undefined;
};

const IntroSection = ({ intro }: IntroSectionProps) => {
  const { t } = useTranslation('common');

  if (!intro) {
    return null;
  }

  return (
    <Box>
      <Text color="rythm.700" variant="desktop-s-semibold" mb={1}>
        {t('profile.application.about.intro')}
      </Text>

      <HighlightText>
        <Text whiteSpace="pre-wrap">{intro}</Text>
      </HighlightText>
    </Box>
  );
};

type LinksSectionProps = {
  member: OpportunityMember;
  links: UserProfileLink[];
  onLinkClick: (link: string) => void;
};

const LinksSection = ({ member, links, onLinkClick }: LinksSectionProps) => {
  const { t } = useTranslation('common');

  const { linkedInUrl } = member;

  if (!linkedInUrl && !links.length) {
    return null;
  }

  return (
    <Box>
      <Text color="rythm.700" variant="desktop-s-semibold" mb={1}>
        {t('profile.application.about.links')}
      </Text>
      <Flex
        w="fit-content"
        align={{ base: 'center', md: 'flex-start' }}
        gap="8px"
      >
        {linkedInUrl && (
          <Tooltip label={t('profile.application.links.linkedIn')}>
            <Link
              href={linkedInUrl}
              textDecoration="none"
              target="_blank"
              onClick={() => onLinkClick(linkedInUrl)}
              onAuxClick={() => onLinkClick(linkedInUrl)}
            >
              <IconButton
                variant="naked"
                icon={<IconLinkedIn />}
                aria-label={t('profile.application.links.ariaLinkedIn')}
              />
            </Link>
          </Tooltip>
        )}
        {links.map(({ id, name, url }) => {
          const Icon = getLinkIcon(url);
          const href = getLinkHref(url);

          return (
            <Tooltip label={name} key={id}>
              <Link
                href={href}
                textDecoration="none"
                target="_blank"
                onClick={() => onLinkClick(url)}
                onAuxClick={() => onLinkClick(url)}
              >
                <IconButton variant="naked" icon={<Icon />} aria-label={name} />
              </Link>
            </Tooltip>
          );
        })}
      </Flex>
    </Box>
  );
};
