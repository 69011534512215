import {
  city,
  dateBetween1900And2000,
  email,
  firstName,
  lastName,
  linkedInUrls,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_CollectiveType,
  Collective_UserType,
  TimePreference,
  WorkPreference,
} from '../../generated';
import { generateCollective } from './collective';
import { generateMember } from './member';

export const generateUser = (
  props?: Partial<Collective_UserType>,
  n = 0
): Collective_UserType => ({
  id: cuid(),
  email: email[n],
  firstname: firstName[n],
  lastname: lastName[n],
  phoneCountry: 'FR',
  phoneNumber: '+33612345678',
  birthDate: dateBetween1900And2000[n],
  createdAt: new Date().toISOString(),
  hasFinishedOnboarding: false,
  hasLegalStructure: true,
  hasSetPassword: true,
  hasCompanyAbroad: false,
  hasRequestedProfileActivation: false,
  hasStructureUnderRegistration: false,
  linkedInUrl: linkedInUrls[n],
  pictureUrl: undefined,
  isGoogleEmail: false,
  members: [],
  dailyRate: 5000,
  workLocation: city[n],
  workPreference: WorkPreference.Flexible,
  timePreference: TimePreference.FullTime,
  notificationNewOpportunity: true,
  notificationOpportunityDeclinedByClient: true,
  notificationOpportunityReminder: true,
  notificationNetworkRequests: true,
  notificationChatReminders: true,
  isRecruiter: false,
  ...props,
});

export const generateUserWithCollective = (
  userProps?: Partial<Collective_UserType>,
  personalSpaceProps?: Partial<Collective_CollectiveType>,
  collectiveProps?: Partial<Collective_CollectiveType>,
  n = 0
) => {
  const user = generateUser(userProps, n);
  const personalSpace = generateCollective(
    {
      personalSpaceUserId: user.id,
      ...personalSpaceProps,
    },
    n
  );
  const collective = generateCollective(collectiveProps, n + 1);
  const member = generateMember({
    userId: user.id,
    user: { ...user, members: [] },
    collective: { ...collective, members: [] },
    collectiveId: collective.id,
  });
  const personalSpaceMember = generateMember({
    userId: user.id,
    user: { ...user, members: [] },
    collective: { ...personalSpace, members: [] },
    collectiveId: personalSpace.id,
  });

  // Link entities for the permission check and to avoid circular JSON
  collective.members = [member];
  personalSpace.members = [personalSpaceMember];
  user.members = [member, personalSpaceMember];

  return { user, personalSpace, collective };
};
