import { ShortlistPricing } from '@collective/data-type';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@collective/ui';
import { ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../../highlighting/highlight-text';
import { SoloApplicationDailyRateBadge } from './solo-application-daily-rate-badge';
import { OpportunityMember } from './types';
import { activeTabProps, SoloApplicationMenus, tabProps } from './utils';

const THRESHOLD_MOTION_BANNER_APPEARANCE = 250;
const MOTION_BANNER_HEIGHT = 135;

type SoloApplicationMotionBannerProps = {
  member: OpportunityMember;
  additionnalActions?: ReactNode;
  parentScrollContainer: RefObject<HTMLDivElement>;
  isPortfolioPresent: boolean;
  shortlistPricing: ShortlistPricing;
  shouldDisplayWorkExperiences: boolean;
  shouldDisplayEducation: boolean;
  hasQuestions: boolean;
};

export const SoloApplicationMotionBanner = ({
  member: { fullname, dailyRate, pictureUrl },
  additionnalActions,
  parentScrollContainer,
  isPortfolioPresent,
  shortlistPricing,
  shouldDisplayWorkExperiences,
  shouldDisplayEducation,
  hasQuestions,
}: SoloApplicationMotionBannerProps) => {
  const { t } = useTranslation('common');
  const [isDisplayed, setIsDisplayed] = useBoolean(false);
  const [currentMenu, setCurrentMenu] = useState<SoloApplicationMenus>(
    SoloApplicationMenus.NEED
  );

  const shouldAppear = useBreakpointValue({ base: false, md: true });

  /**
   * The order of the tabs should follow the order in the page
   * This is used for the calculation of the scroll to know on which tab we are to highlight it
   * Double check the DOM when you update this array
   */
  const tabs = useMemo(
    () => [
      ...(hasQuestions
        ? [
            {
              label: t('profile.application.links.needs'),
              id: SoloApplicationMenus.NEED,
            },
          ]
        : []),
      ...(shouldDisplayWorkExperiences
        ? [
            {
              label: t('profile.application.links.workExperience'),
              id: SoloApplicationMenus.WORK_EXPERIENCE,
            },
          ]
        : []),
      ...(shouldDisplayEducation
        ? [
            {
              label: t('profile.application.links.education'),
              id: SoloApplicationMenus.EDUCATION,
            },
          ]
        : []),
      ...(isPortfolioPresent
        ? [
            {
              label: t('profile.application.links.portfolio'),
              id: SoloApplicationMenus.PORTFOLIO,
            },
          ]
        : []),
    ],
    [
      t,
      shouldDisplayWorkExperiences,
      shouldDisplayEducation,
      isPortfolioPresent,
      hasQuestions,
    ]
  );

  useEffect(() => {
    if (!parentScrollContainer.current) {
      return;
    }

    const parentElement = parentScrollContainer.current;

    const scrollCheck = () => {
      parentElement.scrollTop > THRESHOLD_MOTION_BANNER_APPEARANCE
        ? setIsDisplayed.on()
        : setIsDisplayed.off();

      const reverseTabs = [...tabs].reverse();

      for (const tab of reverseTabs) {
        const element = document.querySelector(`#${tab.id}`);

        if (!element) {
          continue;
        }

        const menuPosition =
          element.getBoundingClientRect().top +
          parentElement.scrollTop -
          MOTION_BANNER_HEIGHT;

        if (parentElement.scrollTop >= menuPosition) {
          setCurrentMenu(tab.id);
          return;
        }
      }

      setCurrentMenu(SoloApplicationMenus.NEED);
    };

    scrollCheck();

    parentElement.addEventListener('scroll', scrollCheck);

    return () => {
      parentElement.removeEventListener('scroll', scrollCheck);
    };
  }, [parentScrollContainer, setIsDisplayed, tabs]);

  // Hidden on mobile
  if (!shouldAppear) {
    return null;
  }

  const goTo = (menu: SoloApplicationMenus) => {
    const target = document.querySelector(`#${menu}`);

    if (target && parentScrollContainer.current) {
      parentScrollContainer.current.scrollTo({
        behavior: 'smooth',
        top:
          target.getBoundingClientRect().top +
          parentScrollContainer.current.scrollTop -
          MOTION_BANNER_HEIGHT,
      });
    }
  };

  return (
    <Box
      position="fixed"
      zIndex={10}
      top={0}
      transform={isDisplayed ? 'translateY(0)' : 'translateY(-100%)'}
      opacity={isDisplayed ? 1 : 0}
      transition="transform .3s ease, opacity .3s ease"
      left={0}
      right={0}
      borderBottom="1px solid"
      borderColor="rythm.300"
      boxShadow="sm"
      pt="20px"
      bg="white"
      h={`${MOTION_BANNER_HEIGHT}px`}
    >
      <Box maxW="786px" w="100%" mx="auto">
        <Flex alignItems="center">
          <Flex flex={1} alignItems="center" gap={4}>
            <Avatar
              name={fullname}
              src={pictureUrl}
              showBorder
              borderColor="rythm.900"
              size="xxlRound"
            />
            <HighlightText>
              <Box>
                <Heading mb={1} variant="desktop-xl-bold">
                  {fullname}
                </Heading>
                {shortlistPricing === ShortlistPricing.DailyRate && (
                  <SoloApplicationDailyRateBadge dailyRate={dailyRate} />
                )}
              </Box>
            </HighlightText>
          </Flex>
          {!!additionnalActions && <Box>{additionnalActions}</Box>}
        </Flex>
        <Flex mt="16px" gap="20px">
          {tabs.map(({ id, label }) => (
            <Text
              key={id}
              variant="desktop-m-medium"
              {...(currentMenu === id ? activeTabProps : tabProps)}
              onClick={() => goTo(id)}
            >
              {label}
            </Text>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
